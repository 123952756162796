import React, { useEffect, useState } from 'react';
import { FilledButton } from '../../../../components/Buttons';
import { CompanyLicense } from './CompanyLicense';
import { get } from '../../../../libs/Requests';
import { InViewSpinner } from '../../../../components/Spinners/InViewSpinner';
import { useNavigate } from 'react-router-dom';
import { useProductData } from '../../../../hooks/useProductData';
import { FilledDownloader } from '../../../downloader/components/FilledDownloader';
import { BorderedDownloader } from '../../../downloader/components/BorderedDownloader';

export interface Subscription {
  productId: string;
  current_period_start: number;
  current_period_end: number;
  id: string;
  seats: number;
  status: string;
  cancel_at: number;
  cancel_at_period_end: boolean;
  start_date: number;
  type: string;
}

export function CompanyLicenses() {
  const { productData } = useProductData({ productId: 'mintrex', lang: 'en' });
  const navigate = useNavigate();
  const [companyLicenses, setCompanyLicenses] = useState<Subscription[] | undefined>();
  const [error, setError] = useState('');

  function purchaseNewLicenseClicked() {
    navigate('/');
  }

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    get('/company/subscriptions', { signal })
      .then(async (companyPurchaseHistoryResponse) => {
        setCompanyLicenses(await companyPurchaseHistoryResponse.json());
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          return;
        }

        setError('Oops, some problems occurred during loading...');
      })
      .finally(() => {});

    return () => {
      abortController.abort();
    };
  }, []);

  if (error) {
    return <>{error}</>;
  }

  if (!companyLicenses) return <InViewSpinner></InViewSpinner>;

  return (
    <>
      <div className="flex flex-col justify-between gap-4 py-4 md:flex-row">
        <div className="my-auto flex justify-between font-semibold md:space-x-4">
          <span>Total Number of Licenses Active:</span>
          <span>{companyLicenses.filter((lic) => lic.status.toLowerCase() === 'active').length}</span>
        </div>

        <div className="w-full md:w-1/4">
          <FilledButton onClick={purchaseNewLicenseClicked}>Purchase New License</FilledButton>
        </div>
      </div>

      <div className="flex flex-col gap-4 pb-4 md:w-1/2 md:flex-row">
        <FilledDownloader
          fileName={productData.binaryFileName!}
          label={`Download ${productData.name}`}
          versionLabel={productData.binaryFileVersion}></FilledDownloader>
        <BorderedDownloader
          fileName={productData.manualFileName!}
          label={'Download manual'}></BorderedDownloader>
      </div>

      {companyLicenses.map((companyLicense) => (
        <CompanyLicense
          key={companyLicense.id}
          subscription={companyLicense}></CompanyLicense>
      ))}
    </>
  );
}
