import React, { useState } from 'react';
import { Input } from '../../../../components/Input';
import { Header, HeaderWrapper } from '../../../../components/Header';
import { resetPassword } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { FullScreenMessage, FullScreenSpinner } from '../../../OrderForm/components/OrderForm/FullScreenSpinner';

export interface ResetPasswordDTO {
  email: string;
}

enum ViewState {
  ResetPassword = 'RESET_PASSWORD',
  ConfirmResetPasswordWithCode = 'CONFIRM_RESET_PASSWORD_WITH_CODE'
}

export function ResetPasswordPage() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isDuringSubmission, setIsDuringSubmission] = useState<boolean>(false);
  const [showSuccessfulMessage] = useState<boolean>(false);
  const [userFormFields, setUserFormFields] = useState<ResetPasswordDTO>({
    email: ''
  });

  function handleChange(name: string, value: string) {
    setUserFormFields({ ...userFormFields, [name]: value });
  }

  async function handleResetPasswordFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsDuringSubmission(true);

    try {
      const _email = userFormFields.email;

      const result = await resetPassword({
        username: _email
      });

      if (result.nextStep.resetPasswordStep == ViewState.ConfirmResetPasswordWithCode) {
        navigate(`/auth/reset_password_code_confirm?email_address=${encodeURIComponent(_email)}`, {
          state: JSON.stringify({
            userName: _email
          })
        });
      }
    } catch (error) {
      const tmp = `${error}`.split('ion: ').pop();

      console.error(tmp);

      setError(tmp as string);
    }

    setIsDuringSubmission(false);
  }

  return (
    <>
      <HeaderWrapper>
        <Header>Reset password</Header>
      </HeaderWrapper>
      <div className="mx-auto w-full bg-white md:w-2/6">
        {isDuringSubmission && <FullScreenSpinner></FullScreenSpinner>}
        {showSuccessfulMessage && (
          <FullScreenMessage message={'The new password has been successfully set.'}></FullScreenMessage>
        )}

        <form
          id="signInForm"
          onSubmit={handleResetPasswordFormSubmit}>
          <Input
            autoComplete="email"
            id="email"
            type="email"
            value={userFormFields.email}
            onChange={(event) => {
              handleChange('email', event.target.value);
            }}
            required={true}
            label="Email address"></Input>

          <div className="flex min-h-8 w-full">
            <span className={error ? 'visible mx-auto self-center text-center text-red-500' : ' invisible'}>
              {error as string}
            </span>
          </div>

          <div className="">
            <Input
              disabled={isDuringSubmission}
              type="submit"
              value="Send reset password code"></Input>
          </div>
        </form>
      </div>
    </>
  );
}
